import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const { user } = useSelector((state) => state.user); // Get the current logged-in user from Redux state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://testdbaac.dodaydream.net/api/users');
        if (!response.ok) throw new Error('Failed to fetch users');
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = (id) => {
    if (user.role !== 'Superadmin') {
      alert('Only Superadmin can edit user roles!');
      return;
    }
    navigate(`/settings/users/edit/${id}`);
  };

  const handleDelete = async (id) => {
    if (user.role !== 'Superadmin') {
      alert('Only Superadmin can delete users!');
      return;
    }

    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const response = await fetch(`https://testdbaac.dodaydream.net/api/users/${id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          setUsers(users.filter((user) => user.id !== id));
        } else {
          console.error('Failed to delete user');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      {user.role === 'Superadmin' && (
        <Button variant="contained" color="primary" onClick={() => navigate('/settings/users/add')}>
          Add New User
        </Button>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Department</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.firstname}</TableCell>
              <TableCell>{user.lastname}</TableCell>
              <TableCell>{user.department}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(user.id)} disabled={user.role !== 'Superadmin'}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(user.id)} disabled={user.role !== 'Superadmin'}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default UserManagement;
