// Import necessary libraries and components
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Snackbar,
} from '@mui/material'; // MUI components
import { createFilterOptions } from '@mui/material/Autocomplete'; // สำหรับการปรับปรุงการค้นหาใน Autocomplete
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  CloudUpload as CloudUploadIcon,
  CameraAlt as CameraAltIcon,
} from '@mui/icons-material'; // MUI icons
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ข้อมูลสำหรับ Site และ Sub Location
const subLocationData = `
Site\tSub Location\tSub-Loc Name
RJ\tRJ_B0_001\tTent ของ WH
RJ\tRJ_B0_002\tที่จอดรถหน้า WH1
RJ\tRJ_B0_003\tSecurity Room
RJ\tRJ_B0_004\tCanteen
RJ\tRJ_B0_005\tNursing
RJ\tRJ_B0_006\tห้องน้ำ (Canteen)
RJ\tRJ_B0_007\tโรงขยะ
RJ\tRJ_B0_008\tโรงจอดรถผู้บริหาร
RJ\tRJ_B0_009\tWaste water treatment plant
RJ\tRJ_B0_010\tห้องเก็บของรอทำลาย
RJ\tRJ_B0_011\tAir compression plant
RJ\tRJ_B0_012\tShop Engineer
RJ\tRJ_B0_013\tDI Water Plant
RJ\tRJ_B0_014\tGas Station
RJ\tRJ_B0_015\tFire protection plant
RJ\tRJ_B0_016\tBoiler Station
RJ\tRJ_B0_017\tFactory Area
RJ\tRJ_B1_fl01.01\tห้องเก็บสารเคมี
RJ\tRJ_B1_fl01.02\tห้องชั่งสารเคมี
RJ\tRJ_B1_fl01.03\tห้องเก็บ Pack mat
RJ\tRJ_B1_fl01.04\tพื้นที่ Receive
RJ\tRJ_B1_fl01.05\tห้อง Office คลัง
RJ\tRJ_B1_fl01.06\tห้องเก็บ FG
RJ\tRJ_B1_fl01.07\tReception
RJ\tRJ_B1_fl01.08\tMeeting 1 Room
RJ\tRJ_B1_fl01.09\tMeeting 2 Room
RJ\tRJ_B1_fl01.11\tห้องน้ำหญิง
RJ\tRJ_B1_fl01.12\tห้องน้ำชาย
RJ\tRJ_B1_fl01.13\tLocker Room
RJ\tRJ_B1_fl01.14\tFilling Room
RJ\tRJ_B1_fl01.15\tMixing Room
RJ\tRJ_B1_fl01.16\tWashing Room
RJ\tRJ_B1_fl01.17\tPacking Room
RJ\tRJ_B1_fl01.18\tOffice Engineer
RJ\tRJ_B1_fl01.19\tLAB QA
RJ\tRJ_B1_fl01.20\tห้องแต่งตัว
RJ\tRJ_B1_fl01.21\tElectrical Room
RJ\tRJ_B1_fl01.22\tData Office Room
RJ\tRJ_B1_fl01.24\tOperation Room
RJ\tRJ_B1_fl02.01\tExecutive Committee Room
RJ\tRJ_B1_fl02.02\tHR Room
RJ\tRJ_B1_fl02.03\tMeeting 5 Room
RJ\tRJ_B1_fl02.04\tMeeting 4 Room
RJ\tRJ_B1_fl02.05\tServer Room
RJ\tRJ_B1_fl02.06\tPantry Room
RJ\tRJ_B1_fl02.09\tDirector,Factory Room
RJ\tRJ_B1_fl02.10\tห้องข้าง Director, Factory Room
RJ\tRJ_B1_fl02.11\tOffice Room 1
RJ\tRJ_B1_fl02.12\tOffice Room 2
RJ\tRJ_B1_fl02.13\tOffice Room 3
RJ\tRJ_B1_fl02.14\tOffice Room 4
RJ\tRJ_B1_fl02.15\tLab Micro
RJ\tRJ_B1_flM.01\tPack mat ชั้นลอย 1
RJ\tRJ_B1_flM.02\tห้องเก็บสารเคมีชั้นลอย
RJ\tRJ_B2_001\tพื้นที่นอกอาคาร 2
RJ\tRJ_B2_fl01.01\tอาคาร 2 ชั้น1 พื้นที่ WH2
RK\tRK_B0_001\tHQ Area
RK\tRK_B01_fl01.01\tCommercial 1
RK\tRK_B01_fl01.02\tReception
RK\tRK_B01_fl01.06\tCommercial 3
RK\tRK_B01_fl01.11\tToilet
RK\tRK_B01_fl02.05\tToilet
RK\tRK_B02_fl02.08\tส่วนกลาง
RK\tRK_B02_fl03.08\Head of Purchasing
RK\tRK_B01_fl00.00\tHQ
RK\tRK_B01_fl01.03\tCommercial 2
RK\tRK_B01_fl01.04\tMeeting 1 Room
RK\tRK_B01_fl01.05\tChief Commercial Officer
RK\tRK_B01_fl01.07\tCCTV Room
RK\tRK_B01_fl01.08\tServer Room
RK\tRK_B01_fl01.09\tCommercial 4
RK\tRK_B01_fl01.10\tKitchen
RK\tRK_B01_fl01.12\tHead Of Commercial
RK\tRK_B01_fl01.13\tCommercial 5
RK\tRK_B01_fl02.01\tHR 1
RK\tRK_B01_fl02.02\tHead of HR
RK\tRK_B01_fl02.03\tLegal
RK\tRK_B01_fl02.04\tCompany Secretary
RK\tRK_B01_fl02.06\tMarketing
RK\tRK_B01_fl02.07\tChief Financial Officer
RK\tRK_B01_fl02.08\tKitchen
RK\tRK_B01_fl02.09\tExecutive Assistant
RK\tRK_B01_fl02.10\tChief Executive Officer
RK\tRK_B01_fl03.01\tFounder Room
RK\tRK_B01_fl03.02\tFounder Room (ข้างห้อง)
RK\tRK_B01_fl03.03\tFounder Room (หน้าทางเข้าห้อง FD)
RK\tRK_B01_fl03.04\tKitchen
RK\tRK_B01_fl03.05\tMarketing Room
RK\tRK_B01_fl03.06\tMeeting Room
RK\tRK_B01_fl04.01\tดาดฟ้า อาคาร 1
RK\tRK_B02_fl00.00\tHQ
RK\tRK_B02_fl01.01\tพื้นที่ส่วนกลาง
RK\tRK_B02_fl01.02\tDirector, Finance
RK\tRK_B02_fl01.03\tDirector, Accounting
RK\tRK_B02_fl01.04\tSr. Director,Merchandise & Supply
RK\tRK_B02_fl01.05\tFinance
RK\tRK_B02_fl01.06\tCSOO 2
RK\tRK_B02_fl01.07\tห้องเก็บเอกสาร
RK\tRK_B02_fl01.08\tCreative
RK\tRK_B02_fl01.09\tMarketing
RK\tRK_B02_fl01.10\tCSOO 1
RK\tRK_B02_fl01.11\tChief Financial Officer
RK\tRK_B02_fl01.12\tInter
RK\tRK_B02_fl01.13\tCanteen 1
RK\tRK_B02_fl01.14\tMeeting 6
RK\tRK_B02_fl01.15\tMeeting 7
RK\tRK_B02_fl01.16\tGA
RK\tRK_B02_fl01.17\tAccounting
RK\tRK_B02_fl01.18\tCanteen 2
RK\tRK_B02_fl01.19\tMaintenance
RK\tRK_B02_fl01.20\tห้องคนขับรถ
RK\tRK_B02_fl02.01\tRD1
RK\tRK_B02_fl02.02\tRD Meeting Room
RK\tRK_B02_fl02.03\tRD2
RK\tRK_B02_fl02.04\tRD3
RK\tRK_B02_fl02.05\tTrade mkt. (พี่ออร่า)
RK\tRK_B02_fl02.06\tMerchandise
RK\tRK_B02_fl02.07\tTrade mkt. (พี่เชษ)
RK\tRK_B02_fl02.09\tHR 2 
RK\tRK_B02_fl02.10\tSale Equip&Lifestyle
RK\tRK_B02_fl02.11\tMeeting 8
RK\tRK_B02_fl03.01\tQA1
RK\tRK_B02_fl03.02\tQA2
RK\tRK_B02_fl03.03\tIT
RK\tRK_B02_fl03.04\tIA
RK\tRK_B02_fl03.05\tKitchen
RK\tRK_B02_fl03.06\tห้องเก็บของจัดซื้อ
RK\tRK_B02_fl03.07\tPurchasing
RK\tRK_B02_fl03.09\tOnline
RK\tRK_B02_fl03.10\tMKT
RK\tRK_B02_fl03.11\tDMP
RK\tRK_B02_fl03.12\tPurchasing Meeting Room
RK\tRK_B03_fl00.00\tHQ
RK\tRK_B03_fl01.01\tArea
RK\tRK_B03_fl01.02\tNursing
RK\tRK_B03_fl01.03\tStudio Room
RK\tRK_B03_fl01.04\tIT Meeting Room
RK\tRK_B03_fl01.05\tIT Room
RK\tRK_B03_fl01.06\tห้องเก็บของ
RK\tRK_B03_fl02.01\tArea
RK\tRK_B03_fl03.01\tArea
RK\tRK_S01_fl01\tMV_Siam Square
RK\tRK_S02_fl00\tBR_Fashion Island
RK\tRK_S03_fl00\tJS_The Mall Bangkae
RK\tRK_S04_fl00\tJS_The Mall Bangkapi
RK\tRK_S05_fl00\tMV_Central  Eastille
RK\tRK_S06_fl00\tMV_Central Chiang Mai
RK\tRK_S08_fl00\tMV_Central Westville
RK\tRK_S09_fl00\tMV_Central World
RK\tRK_S13_fl00\tMV_Fashion Island
RK\tRK_S15_fl00\tMV_Mega Bangna
RK\tRK_S16_fl00\tMV_Siam Discovery
RK\tRK_S18_fl00\tMV_Terminal 21 Asok
RK\tRK_S19_fl00\tRC_The Mall Bangkae
RK\tRK_V01_00\tExtreme Advance
RK\tRK_V02_00\tTanat Intertech
SN\tSN_FFM_01\tพื้นที่ FFM
SN\tSN_PM_01\tพื้นที่คลังสินค้า PM
SN\tSN_WH0_00\tพื้นที่ชั้นลอย WHA-WHD
SN\tSN_WHA_01\tพื้นที่ Office คลัง A
SN\tSN_WHA_02\tห้อง Canteen
SN\tSN_WHA_03\tห้องควบคุมอณุหภูมิ 1
SN\tSN_WHA_06\tห้องประชุม 1
SN\tSN_WHA_07\tห้องประชุม 2 รวมห้อง COO
SN\tSN_WHA_08\tห้องผลิต 1
SN\tSN_WHA_09\tห้องผลิต 2
SN\tSN_WHA_10\tArea rack คลัง A
SN\tSN_WHA_12\tสำนักงาน SC
SN\tSN_WHA_13\tพื้นที่ QC/QA
SN\tSN_WHA_14\tพื้นที่ PST
SN\tSN_WHA_15\tห้อง Server
SN\tSN_WHB_01\tQC Outbound
SN\tSN_WHB_02\tพื้นที่ RTN
SN\tSN_WHB_04\tArea rack คลัง B
SN\tSN_WHB_05\tพื้นที่ Transport
SN\tSN_WHB_06\tพื้นที่ PST
SN\tSN_WHB_07\tห้องเก็บของ GA
SN\tSN_WHB_08\tWH-Office Room 1
SN\tSN_WHC_01\tArea rack คลัง C
SN\tSN_WHD_01\tArea rack คลัง D
SN\tSN_WHD_02\tพื้นที่จอดรถ คลัง D
`;

// Parse the data
const subLocationEntries = subLocationData
  .trim()
  .split('\n')
  .slice(1) // Skip the header row
  .map((line) => {
    const [site, code, name] = line.trim().split('\t');
    return { site, code, name };
  });

// Get unique sites
const sitesData = Array.from(new Set(subLocationEntries.map((entry) => entry.site)));

// Create subLocationsBySite mapping
const subLocationsBySite = {};
subLocationEntries.forEach(({ site, code, name }) => {
  if (!subLocationsBySite[site]) {
    subLocationsBySite[site] = [];
  }
  subLocationsBySite[site].push({ code, name });
});

const EditAsset = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const [asset, setAsset] = useState(null);
  const [originalAsset, setOriginalAsset] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [sites, setSites] = useState(sitesData); // ใช้ข้อมูล sites ที่ได้จากการ parse
  const [subLocations, setSubLocations] = useState([]);
  const [employeeResponsibles, setEmployeeResponsibles] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [imageFiles, setImageFiles] = useState({});
  const [uploadedImages, setUploadedImages] = useState({});
  const [cameraOpen, setCameraOpen] = useState(false);
  const [currentImageField, setCurrentImageField] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false); // สำหรับ Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(''); // ข้อความของ Snackbar
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // ระดับความรุนแรงของ Snackbar

  // รายการแผนกที่กำหนดไว้
  const departments = [
    'แผนก Factory',
    'แผนก EN',
    'แผนก HRBP',
    'แผนก EHS',
    'แผนก Document Control Center',
    'แผนก QMR',
    'แผนก GA',
    'แผนก MIS&IT',
    'แผนก Shipment Control RJ',
    'แผนก Accounting GL/AP',
    'แผนก Product Development (Beauty)',
    'แผนก Graphic Design',
    'แผนก Research & Development',
    'แผนก Sourcing (Beauty)',
    'แผนก Shipment Control',
    'แผนก Accounting Costing',
    'แผนก Risk Management',
    'แผนก Accounting AR/Inventory',
    'แผนก Logistics',
    'แผนก Demand Planning',
    'แผนก Merchandise Management1',
    'แผนก Talent Acquisition',
    'แผนก Brand Management (Skin care and Personal Care)',
    'แผนก Sales (Equipment and Lifestyle)',
    'แผนก Trade Marketing (Beauty)',
    'แผนก Sales (Skin care and Personal Care)',
    'แผนก Marketing (Lifestyle)',
    'แผนก Brand Management (Lifestyle)',
    'แผนก Field Operation',
    'แผนก HRM',
    'แผนก Visual Merchandise',
    'แผนก Regulatory Affairs',
    'แผนก QA',
    'แผนก Sourcing (Lifestyle)',
    'แผนก Service Center',
    'แผนก Marketing (Beauty)',
    'แผนก Digital Communication',
    'แผนก HRD',
    'แผนก Brand Management (Equipment)',
    'แผนก Trade Marketing (Lifestyle)',
    'แผนก Store Design',
    'แผนก Internal Audit',
    'แผนก Legal',
    'แผนก Finance',
    'แผนก E-Commerce',
    'แผนก Business Development',
    'แผนก Company Secretary',
    'แผนก Management',
  ];

  // ฟังก์ชันสำหรับการดึงข้อมูลด้วย token
  const fetchWithToken = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Authentication token is missing.');

    const headers = {
      Authorization: `Bearer ${token}`,
      ...options.headers,
    };

    const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch data');
    }
    return await response.json();
  };

  // ฟังก์ชันสำหรับจัดการการ Assign Ownership
  const handleOwnershipAssignment = (value) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      Employee_Responsible: value || '',
      checklist_4: 'Yes',
    }));
    // แสดงข้อความแจ้งเตือน
    showSnackbar('ได้ทำการ Assign ผู้รับผิดชอบเรียบร้อยแล้ว', 'info');
  };

  // ฟังก์ชันสำหรับจัดการการเลือก Supplier
  const handleSupplierAssignment = (value) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      Supplier: value || '',
      checklist_4: 'Yes',
    }));
    // แสดงข้อความแจ้งเตือน
    showSnackbar('ได้ทำการเลือก Supplier เรียบร้อยแล้ว', 'info');
  };

  const allowedSuppliers = [
    'บริษัท สุขสวัสดิ์ คอนเวิร์ทติ้ง เซ็นเตอร์ จำกัด',
    'บริษัท อาคเนย์อุตสาหกรรมบรรจุภัณฑ์ จำกัด',
    'บริษัท ไทยอ๊อฟเซท จำกัด',
    'บริษัท ยูนิเวอร์สแพ็ค จำกัด',
    'บริษัท เวิลด์แพคเกจจิ้ง อินดัสตรี้ จำกัด',
    'บริษัท ชริ้งเฟล็กซ์ (ประเทศไทย) จำกัด (มหาชน)',
  ];

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [
          assetDetail,
          employeesData,
          employeeResponsiblesData,
          suppliersData,
        ] = await Promise.all([
          fetchWithToken(`https://testdbaac.dodaydream.net/api/fixed_assets/${id}`),
          fetchWithToken('https://testdbaac.dodaydream.net/api/employees'),
          fetchWithToken('https://testdbaac.dodaydream.net/api/employee_responsibles'),
          fetchWithToken('https://testdbaac.dodaydream.net/api/suppliers'),
        ]);

        setAsset(assetDetail);
        setOriginalAsset(assetDetail);
        setEmployees(employeesData);
        setEmployeeResponsibles(employeeResponsiblesData);
        setSuppliers(suppliersData);
        // ไม่ต้องดึงข้อมูล sites จาก API เพราะเราใช้ข้อมูลที่ parse แล้ว
        // setSites(sitesData); // sitesData ถูกตั้งค่าใน useState แล้ว
      } catch (error) {
        setError(error.message);
        // แสดงข้อความผิดพลาด
        showSnackbar('เกิดข้อผิดพลาดในการดึงข้อมูล', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [id]);

  // การจัดการการเปลี่ยนแปลง Site
  const handleSiteChange = (event, newValue) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      Site: newValue || '',
      Sub_Location: '',
      checklist_1: 'No', // Reset checklist_1 to 'No' when Site changes
    }));
    if (newValue) {
      setSubLocations(subLocationsBySite[newValue] || []);
    } else {
      setSubLocations([]);
    }
    // แสดงข้อความแจ้งเตือน
    showSnackbar('ได้ทำการเปลี่ยน Site เรียบร้อยแล้ว', 'info');
  };

  // การจัดการการเปลี่ยนแปลง Sub Location
  const handleSubLocationChange = (event, newValue) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      Sub_Location: newValue ? `${newValue.code} ${newValue.name}` : '',
      checklist_1: newValue ? 'Yes' : 'No', // Set checklist_1 to 'Yes' only if a Sub Location is selected
    }));
    // แสดงข้อความแจ้งเตือน
    showSnackbar('ได้ทำการเปลี่ยน Sub Location เรียบร้อยแล้ว', 'info');
  };

  // การจัดการการเปลี่ยนแปลงใน TextFields และ Selects
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAsset((prevAsset) => {
      const updatedAsset = { ...prevAsset, [name]: value };

      if (name === 'Status_Tag') {
        updatedAsset.checklist_2 = 'Yes';
      }

      if (name === 'STATUS') {
        updatedAsset.checklist_3 = 'Yes';
      }

      return updatedAsset;
    });
    // แสดงข้อความแจ้งเตือน
    showSnackbar(`ได้ทำการเปลี่ยน ${name} เป็น ${value}`, 'info');
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setAsset((prevAsset) => ({
      ...prevAsset,
      [name]: checked ? 'Yes' : 'No',
    }));
    // แสดงข้อความแจ้งเตือน
    showSnackbar(`ได้ทำการเปลี่ยน ${name} เป็น ${checked ? 'Yes' : 'No'}`, 'info');
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      setImageFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
      setUploadedImages((prevImages) => ({
        ...prevImages,
        [name]: URL.createObjectURL(file),
      }));
      // แสดงข้อความแจ้งเตือน
      showSnackbar(`อัปโหลดรูปภาพ ${name} เรียบร้อยแล้ว`, 'success');
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('User is not authenticated.');

      // ตรวจสอบเงื่อนไขก่อนบันทึก
      if (
        (user.role === 'User' && asset.usercheck !== 'Yes') ||
        ((user.role === 'Superadmin' || user.role === 'Acc') && asset.Check_By_AC !== 'Yes')
      ) {
        setError('กรุณาเลือก User Check หรือ Check By AC เป็น Yes ก่อนบันทึกข้อมูล');
        // แสดงข้อความผิดพลาด
        showSnackbar('กรุณาเลือก User Check หรือ Check By AC เป็น Yes ก่อนบันทึกข้อมูล', 'error');
        return;
      }

      // ยืนยันการบันทึกข้อมูล
      if (!window.confirm('คุณต้องการบันทึกข้อมูลหรือไม่?')) {
        return;
      }

      const formData = new FormData();
      formData.append('Audit_By', user.employee_id || '');

      const changedFields = {};
      Object.keys(asset).forEach((key) => {
        if (key !== 'last_edit' && key !== 'id') {
          const originalValue = originalAsset[key];
          const currentValue = asset[key];

          if (currentValue !== originalValue) {
            changedFields[key] = currentValue || '';
            formData.append(key, currentValue || '');
          }
        }
      });

      // Handle Check_By_AC and usercheck updates
      if (asset.Check_By_AC !== originalAsset.Check_By_AC) {
        formData.append('Check_By_AC_Changed_By', user.employee_id);
      }

      if (asset.usercheck !== originalAsset.usercheck) {
        formData.append('User_Check_Changed_By', user.employee_id);
      }

      // รวมไฟล์รูปภาพใหม่
      Object.keys(imageFiles).forEach((key) => {
        formData.append(key, imageFiles[key]);
      });

      if (Object.keys(changedFields).length === 0 && Object.keys(imageFiles).length === 0) {
        setSuccess('ไม่มีการเปลี่ยนแปลงข้อมูล');
        // แสดงข้อความแจ้งเตือน
        showSnackbar('ไม่มีการเปลี่ยนแปลงข้อมูล', 'info');
        return;
      }

      const response = await fetch(`https://testdbaac.dodaydream.net/api/fixed_assets/${id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': token,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update asset');
      }

      setSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
      // แสดงข้อความแจ้งเตือน
      showSnackbar('บันทึกข้อมูลเรียบร้อยแล้ว', 'success');
      setTimeout(() => navigate(`/asset/${id}`), 1500);
    } catch (error) {
      setError(error.message);
      // แสดงข้อความผิดพลาด
      showSnackbar(`เกิดข้อผิดพลาด: ${error.message}`, 'error');
    }
  };

  const readOnlyStyles = {
    backgroundColor: '#f5f5f5',
    color: '#333',
    fontWeight: 'bold',
  };

  const openCameraDialog = (imageField) => {
    setCurrentImageField(imageField);
    setCameraOpen(true);
  };

  const handleCameraCapture = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setImageFiles((prevFiles) => ({ ...prevFiles, [currentImageField]: file }));
    setUploadedImages((prevImages) => ({
      ...prevImages,
      [currentImageField]: URL.createObjectURL(file),
    }));
    setCameraOpen(false);
    // แสดงข้อความแจ้งเตือน
    showSnackbar(`ถ่ายภาพ ${currentImageField} เรียบร้อยแล้ว`, 'success');
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleOpenImagePreview = (imageUrl) => {
    setPreviewImageUrl(imageUrl);
    setImagePreviewOpen(true);
  };

  const handleCloseImagePreview = () => {
    setImagePreviewOpen(false);
    setPreviewImageUrl('');
  };

  const getStatusComponents = (status) => {
    if (status === 'Yes') {
      return (
        <Box display="flex" alignItems="center" style={{ color: 'green' }}>
          <CheckCircleIcon style={{ marginRight: '4px' }} />
          <Typography variant="body2">YES</Typography>
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center" style={{ color: 'red' }}>
          <CancelIcon style={{ marginRight: '4px' }} />
          <Typography variant="body2">NO</Typography>
        </Box>
      );
    }
  };

  const renderImageReadOnly = (label, fieldName) => {
    const imageUrl = asset[fieldName]
      ? `https://testdbaac.dodaydream.net/images/assets/${asset[fieldName]}`
      : 'https://testdbaac.dodaydream.net/images/placeholder.png';
    return (
      <Box mt={2} key={fieldName}>
        <InputLabel>{`${label}`}</InputLabel>
        <Box display="flex" gap={2} alignItems="center">
          <img
            src={imageUrl}
            alt={label}
            style={{
              width: '150px',
              height: '150px',
              objectFit: 'cover',
              marginRight: '16px',
              cursor: 'pointer',
            }}
            onClick={() => handleOpenImagePreview(imageUrl)}
          />
        </Box>
      </Box>
    );
  };

  const renderImage = (label, fieldName) => {
    const imageUrl =
      uploadedImages[fieldName] ||
      (asset[fieldName]
        ? `https://testdbaac.dodaydream.net/images/assets/${asset[fieldName]}`
        : 'https://testdbaac.dodaydream.net/images/placeholder.png');
    return (
      <Box mt={2} key={fieldName}>
        <InputLabel>{`${label}`}</InputLabel>
        <Box display="flex" gap={2} alignItems="center">
          <img
            src={imageUrl}
            alt={label}
            style={{
              width: '150px',
              height: '150px',
              objectFit: 'cover',
              marginRight: '16px',
              cursor: 'pointer',
            }}
            onClick={() => handleOpenImagePreview(imageUrl)}
          />
          <input
            accept="image/*"
            id={`upload-${fieldName}`}
            name={fieldName}
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor={`upload-${fieldName}`}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              อัปโหลดรูปภาพ
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CameraAltIcon />}
            onClick={() => openCameraDialog(fieldName)}
          >
            ถ่ายภาพ
          </Button>
        </Box>
      </Box>
    );
  };

  // การแสดงผล usercheck และ Check_By_AC ตามบทบาทของผู้ใช้
  const renderUserCheckAndCheckByAC = () => {
    return (
      <>
        {/* แสดงฟิลด์ usercheck เฉพาะสำหรับผู้ใช้ที่มีบทบาทเป็น 'User' */}
        {user.role === 'User' && (
          <FormControl fullWidth margin="normal">
            <InputLabel>User Check</InputLabel>
            <Select
              label="User Check"
              name="usercheck"
              value={asset.usercheck || ''}
              onChange={handleInputChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        )}

        {/* แสดงฟิลด์ Check_By_AC เฉพาะสำหรับผู้ใช้ที่มีบทบาทเป็น 'Superadmin' หรือ 'Acc' */}
        {(user.role === 'Superadmin' || user.role === 'Acc') && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Check By AC</InputLabel>
            <Select
              label="Check By AC"
              name="Check_By_AC"
              value={asset.Check_By_AC || ''}
              onChange={handleInputChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        )}
      </>
    );
  };

  // ฟังก์ชันสำหรับแสดง Snackbar
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) return <p>กำลังโหลดข้อมูล...</p>;
  if (error) return <Alert severity="error">{error}</Alert>;

  // กำจัดรายการซ้ำจาก employeeResponsibles และ departments
  const uniqueEmployeeResponsibles = Array.from(new Set(employeeResponsibles));
  const uniqueDepartments = Array.from(new Set(departments));

  // รวมพนักงานและแผนกสำหรับ Autocomplete และกำจัดรายการซ้ำ
  const combinedOptions = Array.from(
    new Set([...uniqueEmployeeResponsibles, ...uniqueDepartments])
  );

  // ปรับปรุงการกรองผลลัพธ์ใน Autocomplete
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option,
  });

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>
        แก้ไขข้อมูลทรัพย์สิน
      </Typography>

      {success && <Alert severity="success">{success}</Alert>}

      {asset && (
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            {/* คอลัมน์ซ้าย */}
            <Grid item xs={12} md={6}>
              {/* ฟิลด์อ่านอย่างเดียว */}
              <TextField
                label="Inventory ID"
                value={asset.Inventory_ID}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
                style={readOnlyStyles}
              />

              <TextField
                label="Asset Name"
                name="Assetname"
                value={asset.Assetname || ''}
                margin="normal"
                InputProps={{ readOnly: true }}
                fullWidth
                style={readOnlyStyles}
              />

              <TextField
                label="Employee/Department Responsible"
                name="Employee/Department Responsible"
                value={asset.Employee_Responsible || ''}
                margin="normal"
                InputProps={{ readOnly: true }}
                fullWidth
                style={readOnlyStyles}
              />

              <TextField
                label="Serial No"
                name="Serial_No"
                value={asset.Serial_No || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Note"
                name="Note"
                value={asset.Note || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <Typography variant="h5">รูปภาพใหม่สำหรับปี 2024</Typography>
              {/* อัปโหลดรูปภาพ */}
              {renderImage('รูปภาพที่ 1', 'Picture_11')}
              {renderImage('รูปภาพที่ 2', 'Picture_21')}
            </Grid>

            {/* คอลัมน์ขวา */}
            <Grid item xs={12} md={6}>
              {/* ส่วนของ Checklist */}
              <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                เช็คลิสต์
              </Typography>
              <Grid container spacing={2}>
                <Divider style={{ margin: '16px 0' }} />
                {/* Checklist 1 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_1 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_1"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_1 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            1. ระบุความถูกต้องของสถานที่ตั้งทรัพย์สิน:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าสถานที่ตั้งตรงกับ Master ให้เลือก "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าสถานที่ตั้งไม่ตรงกับ Master ให้เลือก "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />

                  {/* ฟิลด์ Site */}
                  {asset.checklist_1 === 'No' ? (
                    <Autocomplete
                      options={sites}
                      getOptionLabel={(option) => option || ''}
                      value={asset.Site || ''}
                      onChange={handleSiteChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Site" margin="normal" fullWidth />
                      )}
                    />
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_1)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Site: {asset.Site || 'N/A'}
                      </Typography>
                    </Box>
                  )}

                  {/* ฟิลด์ Sub Location */}
                  {asset.checklist_1 === 'No' ? (
                    subLocations.length > 0 ? (
                      <Autocomplete
                        options={subLocations}
                        getOptionLabel={(option) => `${option.code} ${option.name}`}
                        value={
                          subLocations.find(
                            (subLoc) => `${subLoc.code} ${subLoc.name}` === asset.Sub_Location
                          ) || null
                        }
                        onChange={handleSubLocationChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Location + Sub Loc Name"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    ) : (
                      <Alert severity="warning">
                        ไม่พบ Sub Location สำหรับ Site ที่เลือก
                      </Alert>
                    )
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_1)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Sub Location: {asset.Sub_Location || 'N/A'}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Divider style={{ margin: '16px 0' }} />

                {/* Checklist 2 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_2 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_2"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_2 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            2. ระบุความสมบูรณ์ของป้ายแสดงรหัสทรัพย์สิน:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าความสมบูรณ์ของป้ายถูกต้อง ให้เลือก "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าความสมบูรณ์ของป้ายไม่ถูกต้อง ให้เลือก "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />

                  {/* Status Tag */}
                  {asset.checklist_2 === 'No' ? (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Status Tag</InputLabel>
                      <Select
                        label="Status Tag"
                        name="Status_Tag"
                        value={asset.Status_Tag || ''}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="ปกติ">ปกติ</MenuItem>
                        <MenuItem value="ชำรุด">ชำรุด/สูญหาย</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_2)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Status Tag: {asset.Status_Tag || 'N/A'}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Divider style={{ margin: '16px 0' }} />

                {/* Checklist 3 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_3 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_3"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_3 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            3. สถานะการใช้งานทรัพย์สิน:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าสถานะการใช้งานทรัพย์สินถูกต้อง ให้เลือก "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าสถานะการใช้งานทรัพย์สินมีปัญหา ให้เลือก "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />

                  {/* Status Asset */}
                  {asset.checklist_3 === 'No' ? (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Status Asset</InputLabel>
                      <Select
                        label="Status Asset"
                        name="STATUS"
                        value={asset.STATUS || ''}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="ใช้งานปกติ">ใช้งานปกติ</MenuItem>
                        <MenuItem value="ไม่ได้ใช้งาน">ไม่ได้ใช้งาน</MenuItem>
                        <MenuItem value="ชำรุด/เสื่อมสภาพ">ชำรุด/เสื่อมสภาพ</MenuItem>
                        <MenuItem value="ไม่พบทรัพย์สิน">ไม่พบทรัพย์สิน</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_3)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Status: {asset.STATUS || 'N/A'}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Divider style={{ margin: '16px 0' }} />

                {/* Checklist 4 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_4 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_4"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_4 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            4. ชื่อผู้ครอบครองทรัพย์สินได้ Assign ให้ผู้รับผิดชอบแล้ว:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าชื่อผู้ครอบครองทรัพย์สินได้ Assign ให้ผู้รับผิดชอบแล้ว ให้เลือก "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าไม่สามารถระบุชื่อผู้ครอบครองทรัพย์สินได้ ให้เลือก "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />
                </Grid>
              </Grid>

              {/* ส่วนของ Assign Ownership */}
              {asset.checklist_4 === 'No' && (
                <Box mt={2}>
                  <Typography variant="h6">Assign ผู้รับผิดชอบ</Typography>

                  <Autocomplete
                    options={combinedOptions}
                    getOptionLabel={(option) => option || ''}
                    filterOptions={filterOptions}
                    value={asset.Employee_Responsible || ''}
                    onChange={(event, newValue) => handleOwnershipAssignment(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee/Department Responsible"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />

                  {/* ปรับปรุงส่วนนี้ */}
                  <Autocomplete
                    options={allowedSuppliers}
                    getOptionLabel={(option) => option || ''}
                    value={asset.Supplier || ''}
                    onChange={(event, newValue) => handleSupplierAssignment(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Supplier" margin="normal" fullWidth />
                    )}
                  />
                </Box>
              )}
              <Divider style={{ margin: '36px 0' }} />
              {/* แสดงฟิลด์ usercheck และ Check_By_AC */}
              {renderUserCheckAndCheckByAC()}

              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  color="info"
                  onClick={handleOpenPreview}
                  style={{ marginRight: '8px' }}
                >
                  แสดงตัวอย่างการเปลี่ยนแปลง
                </Button>
                <Button variant="contained" color="success" onClick={handleSave}>
                  บันทึกการเปลี่ยนแปลง
                </Button>
              </Box>
              <Divider style={{ margin: '36px 0' }} />
            </Grid>
          </Grid>
        </form>
      )}
      <Divider style={{ margin: '36px 0' }} />
      {/* Dialog สำหรับการถ่ายภาพ */}
      <Dialog open={cameraOpen} onClose={() => setCameraOpen(false)}>
        <DialogTitle>ถ่ายภาพ</DialogTitle>
        <DialogContent>
          <input
            accept="image/*"
            type="file"
            capture="environment"
            onChange={handleCameraCapture}
            style={{ display: 'none' }}
            id="camera-input"
          />
          <label htmlFor="camera-input">
            <Button variant="contained" color="primary" component="span">
              ถ่ายภาพ
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCameraOpen(false)} color="primary">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog สำหรับการ Preview */}
      <Dialog open={previewOpen} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogTitle>แสดงตัวอย่างการเปลี่ยนแปลง</DialogTitle>
        <DialogContent dividers>
          {/* คุณสามารถเพิ่มข้อมูลที่ต้องการแสดงใน preview ได้ที่นี่ */}
          <Typography variant="body1">รายละเอียดการเปลี่ยนแปลงจะปรากฏที่นี่</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview} color="error">
            ปิด
          </Button>
          <Button onClick={handleSave} color="success" variant="contained">
            บันทึกการเปลี่ยนแปลง
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal สำหรับการแสดงภาพขนาดเต็ม */}
      <Dialog open={imagePreviewOpen} onClose={handleCloseImagePreview} maxWidth="lg" fullWidth>
        <DialogContent>
          <img
            src={previewImageUrl}
            alt="Full Preview"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImagePreview} color="error">
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar สำหรับการแจ้งเตือน */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default EditAsset;
